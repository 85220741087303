import React, {useState, useEffect} from 'react';
import Landing from './components/Landing';

const App = () => {

  const scenes = [
      'landing',
      'video',
      'thanks'
  ];
  const [scene, setScene] = useState(scenes[0]);
  const [smallDevice, setSmallDevice] = useState(false);

  useEffect(() => {
    setSmallDevice(getPhoneVersion())
  }, [])

  const goToScene = scene => {
    if (scenes.includes(scene)) {
      setScene(scene);
    }
  }

  const getPhoneVersion = () => {
    var iHeight = window.screen.height
    if (iHeight <= 736) {
      return true
    } else {
      return false
    }
  }



  const onLandingComplete = () => goToScene('video');

  const onVideoComplete = () => goToScene('thanks');

  return (
    <Landing scene={scene} smallDevice={smallDevice} onLandingComplete={onLandingComplete} onVideoComplete={onVideoComplete} />
  );
}

export default App;
