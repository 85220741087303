import React, {useState, useEffect} from 'react'
import './Logo.scss';
import LogoImg from '../assets/images/logo.png'

const Logo = props => {
  const scene = props.scene;
  const [frame, setFrame] = useState(0);

  useEffect(() => {

      const showLogo = setTimeout(() => setFrame(0), 500);
      const enlargeLogo = setTimeout(() => setFrame(1), 1500);
      const moveLogo = setTimeout(() => setFrame(2), 3000);

      return () => {
          clearTimeout(showLogo);
          clearTimeout(enlargeLogo);
          clearTimeout(moveLogo);
      }
    }
  , [props])

  return (
    <div className={`Logo scene-${scene} frame-${frame}`}>
      <img className="Logo__image" src={LogoImg} alt="logo" draggable="false" />
    </div>
  )
}

export default Logo;
