import React, { useEffect, useState } from 'react';
import * as Player from '@vimeo/player/dist/player';
import './Video.scss';

const Video = props => {
  const {scene, smallDevice} = props;
  const [frame, setFrame] = useState(0);

  useEffect(() => {
    const iframe = document.querySelector('iframe');
    const player = new Player(iframe);

    const fadeVideo = setTimeout(() => {
        setFrame(0);
    }, 0);

    const playVideo = setTimeout(() => {
        player.play();
    }, 100);

    const showVideo = setTimeout(() => {
        setFrame(1);
    }, 500);

    const hideVideo = setTimeout(() => {
        setFrame(2);
    }, 46000);

    const showFooter = setTimeout(() => {
        setFrame(3);
    }, 46500);

    return () => {
        clearTimeout(fadeVideo);
        clearTimeout(showVideo);
        clearTimeout(hideVideo);
        clearTimeout(playVideo);
        clearTimeout(showFooter);
    }
  }, [props]);

    return (
        <div className={`Video scene-${scene} frame-${frame} ${smallDevice && 'smallDevice'}`}>
            <>
                <iframe
                    title="video"
                    src="https://player.vimeo.com/video/431429749?background=1&autoplay=0&portrait=1&controls=0&loop=0&playsinline=1&title=0&transparent=0"
                    width="240"
                    height="426"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen=""
                    data-ready="true"
                ></iframe>
                <script src="https://player.vimeo.com/api/player.js"></script>
                <h1>Thank you!</h1>
                <footer>
                    <p>© Davide Campari-Milano S.p.A.</p>
                    <p>20099 Sesto San Giovanni Via Franco Sacchetti 20</p>
                    <p>P.Iva: 06672120158</p>
                </footer>
            </>
        </div>
    );
}

export default Video;
