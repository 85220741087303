import React, { useEffect, useState } from 'react';
import './Landing.scss';
import Logo from './Logo';
import Video from './Video';

const Landing = props => {
  const {scene, smallDevice} = props;
  const [frame, setFrame] = useState(0);

  useEffect(() => {

    if (props.scene === 'landing') {

    const showLogo = setTimeout(() => setFrame(0), 100);

      return () => {
        clearTimeout(showLogo);
      }
    }

  }, [props]);

  return (
    <div className={`Landing scene-${scene} frame-${frame}`}>
        <Logo scene={scene} />
        <Video scene={scene} smallDevice={smallDevice}/>
    </div>
  );
}

export default Landing;
